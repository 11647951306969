import React, { useEffect, useRef } from "react";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import websitePlugin from "grapesjs-preset-webpage";
import basicBlockPlugin from "grapesjs-blocks-basic";
import formPlugin from "grapesjs-plugin-forms";
import Cookies from "js-cookie"; // Import js-cookie library

function App() {
  const editorRef = useRef(null);

  useEffect(() => {
    const currentUrl = window.location.href;
    const param = currentUrl.split("/")[3];

    // Function to delete the 'gjsProject' cookie
    const deleteGjsProjectCookie = () => {
      Cookies.remove("gjsProject");
    };

    // Delete 'gjsProject' cookie before initializing editor
    deleteGjsProjectCookie();

    // Function to initialize or update the editor based on fetched data
    const initializeEditor = (html, css) => {
      editorRef.current = grapesjs.init({
        container: "#gjs",
        width: "100%",
        components: html,
        style: css,
        plugins: [websitePlugin, basicBlockPlugin, formPlugin],
      });
    };

    // Check if param is provided and fetch data accordingly
    if (param && param !== "them") {
      fetch(`https://thetraining.info/api/page/${param}`)
        .then((response) => response.json())
        .then((data) => {
          console.log("Fetched Data:", data);
          const { html, css } = data.content;

          // Initialize editor with fetched content
          initializeEditor(html, css);

          // Update GrapesJS with new HTML content and CSS styles
          editorRef.current.setComponents(html);
          editorRef.current.setStyle(css);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else if (param && param === "them") {
      const idThem = currentUrl.split("/")[4];
      fetch(`https://thetraining.info/api/them/${idThem}`)
        .then((response) => response.json())
        .then((data) => {
          console.log("Fetched Data:", data);
          const { html, css } = data.them;

          // Initialize editor with fetched content
          initializeEditor(html, css);

          // Update GrapesJS with new HTML content and CSS styles
          editorRef.current.setComponents(html);
          editorRef.current.setStyle(css);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      // Initialize editor with default empty content
      initializeEditor("", "");
    }
  }, []);

  return <div id="gjs"></div>;
}

export default App;
